import '../styles/globals.css';
import { ThemeProvider } from 'next-themes';
import Layout from '../components/layout';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
import { MetaMaskProvider } from 'metamask-react';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useRef } from 'react';
import { SessionProvider } from "next-auth/react"

// import { WagmiConfig, createClient, configureChains } from 'wagmi'
// import { bsc, mainnet, arbitrum, polygon } from 'wagmi/chains'
// import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { publicProvider } from 'wagmi/providers/public'
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig, useAccount, useDisconnect, useConnect } from "wagmi";
import { arbitrum, mainnet, polygon, bsc } from 'wagmi/chains'

import Moralis from 'moralis';
import { EvmChain } from '@moralisweb3/common-evm-utils';


// import { Web3Modal } from "@web3modal/react";

//[alchemyProvider({ apiKey: 'ldBRju-1wztzyqvIwpTREFDAU66kSwIu' }), walletConnectProvider({ projectId: "070a2034d3b90e0e1eb0aab999c157ee" }),

// const { chains, webSocketProvider } = configureChains([mainnet, bsc, arbitrum, polygon], publicProvider(),
// )

const chains = [mainnet, bsc, arbitrum, polygon];
let projectId = "070a2034d3b90e0e1eb0aab999c157ee";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)


// const { provider } = configureChains(chains, [
// 	//walletConnectProvider({ projectId: "070a2034d3b90e0e1eb0aab999c157ee" }),
// 	publicProvider()
//   ]);

if (!Moralis.Core.isStarted) {
    await Moralis.start({ apiKey: process.env.MORALIS_API_KEY });
  }



// Set up client
// const wcclient = createClient({
// 	autoConnect: true,
// 	connectors: 
// 		modalConnectors({ appName: "web3Modal", chains }),
// 		//new MetaMaskConnector({ chains })
// 		// new CoinbaseWalletConnector({
// 		// 	chains,
// 		// 	options: {
// 		// 		appName: 'wagmi',
// 		// 	},
// 		// }),
// 		// new WalletConnectConnector({
// 		// 	chains,
// 		// 	options: {
// 		// 		qrcode: true,
// 		// 	},
// 		// }),
// 		// new InjectedConnector({
// 		// 	chains,
// 		// 	options: {
// 		// 		name: 'Injected',
// 		// 		shimDisconnect: true,
// 		// 	},
// 		// }),
	
// 	provider,
// 	//webSocketProvider,
// })

// Web3Modal Ethereum Client
//const ethereumClient = new EthereumClient(wcclient, chains);



function MyApp({ Component, pageProps: {session, ...pageProps} }) {
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});


	return (
		<>
			<script src="https://widget.mava.app" id="MavaWebChat" data-token="8f6ce40ed2299ee9a886147b6c0ef8cbfcfd3e73440021d653a18e43abcb87a2"></script>
			<Meta title="CryptoCult| Don't Drink the Koolaid" />
			<SessionProvider session={session}>
			<WagmiConfig config={wagmiConfig}>
				<Provider store={store}>
					<ThemeProvider enableSystem={true} attribute="class">
						<MetaMaskProvider>
							<UserContext.Provider value={{ scrollRef: scrollRef }}>
								{pid === '/login' ? (
									<Component {...pageProps} />
								) : (
									<Layout>
										<Component {...pageProps} />
									</Layout>
								)}
							</UserContext.Provider>
						</MetaMaskProvider>
					</ThemeProvider>
				</Provider>
			</WagmiConfig>
			</SessionProvider>
			<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
		</>
	);
}

export default MyApp;
