const footerMenuList = [
  {
    id: 1,
    title: "Schedule",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "#",
        text: "Scheduler",
      },
    ],
  },
  {
    id: 10,
    title: "DAPPS",
    diffClass: "",
    list: [
     
      {
        id: 11,
        href: "/dapps/piston",
        text: "Piston",
      },
    ]

  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
     
      {
        id: 3,
        href: "#",
        text: "Contact Us",
      },
      {
        id: 5,
        href: "#",
        text: "FAQ",
      },
    ],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Profile",
      }
    ],
  },
];

const socialIcons = [

  {
    id: 2,
    href: "https://twitter.com/CryptoCultCurt",
    text: "twitter",
  },
  {
    id: 4,
    href: "https://t.me/cryptominer2049er",
    text: "telegram",
  },
];

export { footerMenuList, socialIcons };
